.slider-swiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}
/* .slider-swiper .swiper-slide {
    width: auto;
} */

.slider-swiper .swiper-pagination-bullet {
    width: 1rem;
    height: 1rem;
    background-color: #ff691b;
}
.slider-swiper .swiper-button-prev, .slider-swiper .swiper-button-next { 
    color: #ff691b;
    font-weight: bold;
}

@media(max-width:767px) {
    .slider-swiper .swiper-slide { width: 90%; }  
    .slider-swiper .swiper-pagination-bullet {
        width: 0.6rem;
        height: 0.6rem;
    }
}
