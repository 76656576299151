.wide-slider-swiper.swiper {
    width: 100%;
}

.wide-slider-swiper .swiper-slide img {
    display: block;
    width: 100%;    
    object-fit: cover;
    /* border-radius: 10px; */
}
  
.wide-slider-swiper .swiper-slide {
    width: 90%;
    max-width: 1200px;
}
