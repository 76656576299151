/* 
анимация подгрузки новостей для transition group 
Уже не актуально, используется framer-motion
*/
.news-item-enter {
    transform: scale(0);
}
.news-item-enter-active {
    transform: scale(1);
    transition: all 500ms ease-in;
}
.news-item-exit {
    transform: scale(1);
}
.news-item-exit-active {
    transform: scale(0);
    transition: all 500ms ease-in;
}
